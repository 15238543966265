import moment from "moment";
import {
  required,
  numeric,
  email,
  min,
  max,
  alpha,
  digits,
  min_value,
  max_value,
  between,
  integer,
} from "vee-validate/dist/rules";
import { extend, setInteractionMode } from "vee-validate";

setInteractionMode("eager");

const dateFormat = "DD-MM-YYYY";
const timeFormat = "HH:mm";
const dateRegEx =
  "^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$";

extend("required", {
  ...required,
  message: "This field is required.",
});
extend("numeric", {
  ...numeric,
  message: "This field may only contain numeric characters.",
});
extend("email", {
  ...email,
  message: "This field must be a valid email.",
});
extend("alpha", {
  ...alpha,
  message: "This field may only contain alphabetic characters.",
});
extend("min", {
  ...min,
  message: "The must be at least {length} characters.",
});
extend("max", {
  ...max,
  message: "This field may not be greater than {length} characters.",
});
extend("min_value", {
  ...min_value,
  message: "This field must be {min} or more.",
});
extend("max_value", {
  ...max_value,
  message: "This field must be {max} or less.",
});
extend("digits", {
  ...digits,
  message: "This field must be numeric and exactly contain {length} digit(s).",
});
extend("between", {
  ...between,
  message: "This field must be between {min} and {max}.",
});
extend("integer", {
  ...integer,
  message: "This field must be an integer.",
});
extend("decimal", {
  params: ["precision", "scale"],
  validate: (value, { precision, scale }) => {
    let re = new RegExp(`^(\\d{0,${precision - scale}}\\.)?\\d{1,${scale}}$`);
    if (re.test(value)) {
      return true;
    }
    return `This field accepts up-to ${
      precision - scale
    } digit(s) and ${scale} decimal place(s).`;
  },
});
extend("current_date_compare", {
  params: ["opt"],
  validate: (value, { opt }) => {
    if (value === null || value === undefined || opt == undefined) {
      return "Parameter error!";
    }    
    if (["lt", "gt", "eq", "lteq", "gteq"].indexOf(opt) == -1) {
      throw new Error(
        `current_date_compare validator has invalid parameter: ${opt}`
      );
    }
    let dVal = moment(value, dateFormat).diff(moment(), "days");
    if (opt == "lt" && dVal >= 0) {
      return `The date entred: ${value}, must be before today's date: ${moment().format(
        dateFormat
      )}`;
    }
    if (opt == "lteq" && dVal > 0) {
      return `The date entred: ${value}, must be on or before today's date: ${moment().format(
        dateFormat
      )}`;
    }
    if (opt == "gt" && dVal <= 0) {
      return `The date entred: ${value}, must be after today's date: ${moment().format(
        dateFormat
      )}`;
    }
    if (opt == "gteq" && dVal < 0) {
      return `The date entred: ${value}, must be on or after today's date: ${moment().format(
        dateFormat
      )}`;
    }
    if (opt == "eq" && dVal != 0) {
      return `The date entred: ${value}, must be same as today's date: ${moment().format(
        dateFormat
      )}`;
    }
    return true;
  },
});
extend("valid_name", {
  validate: (value) => {
    let re = new RegExp("^( ?[a-zA-Z]+([-_'.][a-zA-Z]+)*)+$");
    if (!re.test(value)) {
      return `The name entered '${value}' has invalid character(s)`;
    }
    return true;
  },
});
extend("valid_uk_postcode", {
  validate: (value) => {
    let re = new RegExp(
      "^(([gG][iI][rR] 0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkpstuwA-HJKPSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) [0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$"
    );
    if (!re.test(value)) {
      return "Must be valid UK postcode.";
    }
    return true;
  },
});
extend("age_check", {
  validate: (value, { opt, ageLimit }) => {
    if (value === null || value === undefined) {
      return "Invalid value error!";
    }
    if (["Maximum", "Minimum"].indexOf(opt) == -1) {
      throw new Error(`age_check validator has invalid parameter: ${opt}`);
    }
    let dVal = moment().diff(moment(value, dateFormat), "years", true);
    let dText = Math.floor(dVal).toString();
    if (opt == "Minimum" && dVal < ageLimit) {
      return `Age calculated is: ${dText}, age must be at least: ${ageLimit} years`;
    } else if (opt == "Maximum" && dVal > ageLimit) {
      return `Age calculated is: ${dText}, age must be: ${ageLimit} years or below`;
    }
    return true;
  },
  params: ["opt", "ageLimit"],
});
extend("data_item_compare", {
  validate: (value, { opt, targetValue, adjuster, dataType, msg }) => {
    if (value === null || value === undefined) {
      return "Invalid parameter error!";
    }
    if (targetValue == undefined) {
      return true;
    }
    if (["lt", "gt", "eq", "noteq", "lteq", "gteq"].indexOf(opt) == -1) {
      throw new Error(`data_item_compare validator has invalid parameter:${opt}`);
    }
    let dVal;
    let tVal;    
    if (dataType == "date") {
      if (adjuster === undefined) {
        dVal = moment(value, dateFormat).diff(
          moment(targetValue, dateFormat),
          "days"
        );
        tVal = targetValue;
      } else {
        dVal = moment(value, dateFormat).diff(
          moment(targetValue, dateFormat).add(adjuster, "days"),
          "days"
        );
        tVal = moment(targetValue, dateFormat)
          .add(adjuster, "days")
          .format(dateFormat);
      }

      if (opt == "lt" && dVal >= 0) {
        return msg ? msg : `Date must be before: ${tVal}`;
      } else if (opt == "lteq" && dVal > 0) {
        return msg ? msg : `Date must be on or before: ${tVal}`;
      } else if (opt == "gt" && dVal <= 0) {
        return msg ? msg : `Date must be after: ${tVal}`;
      } else if (opt == "gteq" && dVal < 0) {
        return msg ? msg : `Date must be on or after: ${tVal}`;
      } else if (opt == "eq" && dVal != 0) {
        return msg ? msg : `Date must be same as: ${tVal}`;
      } else if (opt == "noteq" && dVal == 0) {
        return msg ? msg : `Date must not be equal to: ${tVal}`;
      }
    } else if (dataType == "number") {
      if (adjuster === undefined) {
        dVal = value - targetValue;
        tVal = targetValue;
      } else {
        dVal = value - targetValue + adjuster;
        tVal = targetValue + adjuster;
      }
      if (opt == "lt" && dVal >= 0) {
        return msg ? msg : `This field must be less than: ${tVal}`;
      } else if (opt == "lteq" && dVal > 0) {
        return msg ? msg : `This field must be less or equal to: ${tVal}`;
      } else if (opt == "gt" && dVal <= 0) {
        return msg ? msg : `This field must be greater than: ${tVal}`;
      } else if (opt == "gteq" && dVal < 0) {
        return msg ? msg : `This field must be greater or equal to: ${tVal}`;
      } else if (opt == "eq" && dVal != 0) {
        return msg ? msg : `This field must be equal to: ${tVal}`;
      } else if (opt == "noteq=" && dVal == 0) {
        return msg ? msg : `This field must not be equal to: ${tVal}`;
      }
    }
    return true;
  },
  params: ["opt", "targetValue", "adjuster", "dataType", "msg"],
});
extend("valid_date_format", {
  validate: (value) => {
    let re = new RegExp(dateRegEx, "i");
    if (!re.test(value)) {
      return "Date format allowed is 30-12-2018";
    }
    return true;
  },
});
extend("valid_datetime_format", {
  validate: (value) => {
    let re = new RegExp(`${dateFormat} ${timeFormat}`, "i");
    if (!re.test(value)) {
      return "Date-time format allowed is 30-12-2018 23:59";
    }
    return true;
  },
});
extend("valid_time_format", {
  validate: (value) => {
    let re = new RegExp("^[012]{0,1}[0-9]:[0-6][0-9]$");
    if (!re.test(value)) {
      return "Time format allowed is 23:59";
    }
    return true;
  },
});
extend("valid_ddmm_format", {
  validate(value) {
    let re = new RegExp(
      "^([0]?[1-9]|[1-2]\\d|3[0-1])-(JAN|FEB|MAR|APR|MAY|JUN|JULY|AUG|SEP|OCT|NOV|DEC)$",
      "i"
    );
    if (!re.test(value)) {
      return "Day-Month format allowed: example 01-Dec";
    }
    return true;
  },
});
extend("valid_yyyy_format", {
  validate: (value) => {
    let re = new RegExp("^[012]{0,1}[0-9]:[0-6][0-9]$", "i");
    if (!re.test(value)) {
      return "Year format allowed is NNNN: example 2018";
    }
    return true;
  },
});
extend("valid_azure_b2c_password_strong", {
  validate: (value) => {
    let re = new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$");
    if (!re.test(value)) {
      return "Password requirements are not met. Password must be minimum 8 and upto 64 characters. Must contain at least one each of the following character sets; one lowercase, one uppercase, one numbers and optional symbols.";
    }
    return true;
  },
});
extend("valid_uk_mobile", {
  validate: (value) => {
    let re = new RegExp("^[1-9][0-9]+$");
    if (!re.test(value)) {
      return "Must be up to 10 digits with no leading zeros.";
    }
    return true;
  },
});
extend("valid_world_mobile", {
  params: ["countryCode"],
  validate: (value, { countryCode }) => {
    if (countryCode == 44 && !/^[1-9][0-9]{9}$/.test(value)) {
      return "Must be up to 10 digits with no leading zeros.";
    } else if (countryCode == 1 && !/^[2-9]\d{2}\d{3}\d{4}$/.test(value)) {
      return "Must be up to 10 digits with no leading zeros.";
    } else if (countryCode == 47 && !/^\d{8}$/.test(value)) {
      return "Must be up to 8 digits with no leading zeros.";
    } else if (countryCode == 233 && !/^[1-9][0-9]{8}$/.test(value)) {
      return "Must be up to 9 digits with no leading zeros.";
    }
    return true;
  },
});
extend("valid_world_postcode", {
  params: ["countryCode"],
  validate: (value, { countryCode }) => {
    if (
      countryCode == 44 &&
      !/^(([gG][iI][rR] 0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkpstuwA-HJKPSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) [0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/.test(
        value
      )
    ) {
      return "Must be valid UK postcode.";
    } else if (countryCode == 1 && !/^\d{5}(?:-\d{4})?$/.test(value)) {
      return "Must be valid USA ZIP Code.";
    } else if (countryCode == 47 && !/^\d{4}$/.test(value)) {
      return "Must be valid Norway postcode.";
    }
    return true;
  },
});
extend("app_regex", {
  params: ["pattern", "errorMessage"],
  validate: (value, { pattern, errorMessage }) => {
    let re = new RegExp(pattern);
    if (re.test(value)) {
      return true;
    }
    return errorMessage;
  },
});
