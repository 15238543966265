<template>
  <div class="service-area pt--70 pb--70 bg_color--1">
    <v-container>      
      <div
        data-aos="zoom-in"
        data-aos-easing="linear"
        data-aos-duration="500"
        class="text-center section-title mb--30"
      >
        <h3 class="heading-title"><span class="app-title-text-left">Staffing</span> Services</h3>
        <Service class="mt--50" />
      </div>
    </v-container>
  </div>
</template>
<script>
import Service from "../components/Service";
export default {
  components: {
    Service,
  },
};
</script>
