<template>
  <v-row align="start" class="row--35">
    <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
      <div class="text-center section-title mb--50">
        <h2 class="app-header-1">Send us your enquiry</h2>
      </div>
      <ContactForm />
    </v-col>
    <v-col
      lg="6"
      md="6"
      sm="12"
      cols="12"
      order="1"
      order-md="2"
      align-self="center"
    >
      <div
        data-aos="flip-left"
        data-aos-easing="linear"
        data-aos-duration="500"
        class="thumbnail mb_md--40 mb_sm--40"
      >
        <slot name="contact-img"></slot>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import ContactForm from "./ContactForm";
ContactForm;
export default {
  components: {
    ContactForm,
  },
};
</script>
