<template>
  <div class="rn-brand-area ptb--120 app-bg-color-1">
    <v-container>
      <h2 class="text-center app-header-1">CQC overview and inspections</h2>
      <script
        type="application/javascript"
        src="https://www.cqc.org.uk/sites/all/modules/custom/cqc_widget/widget.js?data-id=1-2526373423&data-host=www.cqc.org.uk&type=location"
      ></script>
    </v-container>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.app-header-1 {
  color: #008080;
  font-weight: 500;
}
.app-bg-color-1 {
  background: #f7fbef;
}
</style>
