<template>
  <div>
    <HeaderNav>
      <img slot="logo" :src="logoLight" class="logo-light" />
      <img slot="logo-dark" :src="logoDark" class="logo-dark" />
    </HeaderNav>
    <Slider />
  </div>
</template>
<script>
import HeaderNav from "./HeaderNav";
import Slider from "./Slider";
export default {
  components: {    
    HeaderNav,
    Slider    
  },
  data() {
    return {
      logoLight: require("../assets/images/logo/rhs24_logo_hr_01.png"),
      logoDark: require("../assets/images/logo/rhs24_logo_hr_01.png"),
    };
  },
};
</script>

