<template>
  <GmapMap v-bind="options">
    <GmapInfoWindow
      :options="infoOptions"
      :position="infoWindowPos"
      :opened="infoWinOpen"
      @closeclick="infoWinOpen = false"
    >
    </GmapInfoWindow>
    <GmapMarker
      :key="i"
      v-for="(m, i) in markers"
      :position="m.position"
      :clickable="true"
      @click="toggleInfoWindow(m, i)"
    ></GmapMarker>
  </GmapMap>
</template>
<script>
const mapPosition = { position: { lat: 52.628460, lng: -1.149110 } };
export default {
  data() {
    return {
      options: {
        zoom: 19,
        mapTypeId: "roadmap",
        center: mapPosition.position,
        streetViewControl: true,
      },
      markers: [mapPosition],

      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: `<div class="map-marker-content">
        <h5>RHS24 Care</h5>
        <p>
          Head Office<br>
          12 Briton Street<br> 
          LE3 0AA<br>
          Leicester<br>
          United Kingdom
        </p>
      </div>`,
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  methods: {
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },
};
</script>
<style>
.gm-style img {
  max-width: none !important;
}
</style>
