<template>
  <div
    class="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--4"
    data-black-overlay="5"
  >
    <v-container>
      <v-row>
        <v-col lg="12">
          <div class="inner">
            <div class="content-wrapper">
              <div class="content">
                <h2 class="text-center">Our Values &amp; Principles</h2>
                <div class="text-center pb-5">
                  <v-btn @click="toggleSlider" fab dark small color="#FF6F00">
                    <v-icon v-if="playStatus" dark> mdi-pause </v-icon>
                    <v-icon v-else dark> mdi-play </v-icon>
                  </v-btn>
                </div>
                <VueSlickCarousel ref="carousel1" v-bind="settings">
                  <div>
                    <h4 class="theme-gradient">Privacy</h4>
                    <p>
                      The right of individuals to be left alone or undisturbed
                      and free from intrusion into their affairs. This is taken
                      into account in the formulation of Care Plans, and will
                      only be overridden in exceptional circumstances, and with
                      the knowledge of the service user or their advocate.
                    </p>
                  </div>
                  <div>
                    <h4 class="theme-gradient">Confidentiality</h4>
                    <p>
                      Service user confidentiality is, wherever possible,
                      maintained. On occasion, it will be necessary, for the
                      benefit of the service user or others to share personal
                      information with either other professionals or
                      organisations. Where possible, service users are consulted
                      and their views taken into account.
                    </p>
                  </div>
                  <div>
                    <h4 class="theme-gradient">Dignity &amp; Choice</h4>
                    <p>
                      <b>Dignity</b> All individuals, whatever their
                      circumstances, have the right to be treated with dignity
                      and respect. <br />
                      <b>Choice</b> Services are designed to be accessible and
                      flexible, promoting ordinary lifestyles and based upon
                      client's own choices. Individual choice is promoted,
                      within the limits imposed by service constraints.
                    </p>
                  </div>
                  <div>
                    <h4 class="theme-gradient">Anti-discrimination</h4>
                    <p>
                      Many clients, because of their circumstances, (e.g. age,
                      disability, gender, marital status, sexual orientation,
                      culture, religion or nationality), may find themselves in
                      circumstances in which discrimination can occur. RHS24
                      designs its services and delivery to, wherever possible,
                      promote equality and counteract discrimination where it
                      arises.
                    </p>
                  </div>
                  <div>
                    <h4 class="theme-gradient">Independence</h4>
                    <p>
                      Clients are enabled to live independently. Services are
                      aimed at maximising the individual's capacity for
                      self-care and mobility. Every individual, whatever their
                      circumstances, has the potential for development, and the
                      right to hold personal aspirations. Within the boundaries
                      set by legislation and professional social work ethics,
                      service users are encouraged in achieving their personal
                      goals.
                    </p>
                  </div>
                  <div>
                    <h4 class="theme-gradient">
                      Rights &amp; Responsibilities
                    </h4>
                    <p>
                      <b>Rights</b> The rights of citizenship are safeguarded
                      for all service users. Work should be conducted in a
                      manner that facilitates empowerment and makes appropriate
                      use of advocacy. and respect. <br />
                      <b>Responsibilities</b> Clients are expected to accept
                      appropriate responsibilities, taking into account their
                      particular abilities and circumstances. These may include
                      having due regard for others, for property or for
                      participation in Care Plans.
                    </p>
                  </div>
                  <div>
                    <h4 class="theme-gradient">Family Support</h4>
                    <p>
                      <b>Family Support</b> The value of the contribution of
                      client's family support is fully recognised. The needs of
                      the client we are providing, or intending to provide,
                      regular or substantial care is, where requested, assessed
                      independently of the needs of the client. The outcome of
                      the assessment is taken into account when agreeing Care
                      Plans.
                    </p>
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      playStatus: true,
      settings: {
        autoplay: true,
        autoplaySpeed: 4000,
        infinite: true,
        dots: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1263,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    toggleSlider() {
      this.playStatus = !this.playStatus;
      if (this.playStatus) {
        this.$refs.carousel1.play();
      } else {
        this.$refs.carousel1.pause();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1263px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm-layout: "only screen and (max-width: 767px)";

.rn-finding-us-area {
  .inner {
    position: relative;
    @media #{$lg-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }
    @media #{$md-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }
    @media #{$sm-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }
    .content-wrapper {
      .content {
        background: #ededed;
        padding: 100px 100px;
        position: relative;
        z-index: 2;
        border-radius: 10px;
        @media #{$lg-layout} {
          padding: 60px 50px;
          width: 100%;
          border-radius: 0;
        }
        @media #{$md-layout} {
          padding: 60px 50px;
          width: 100%;
          border-radius: 0;
        }
        @media #{$sm-layout} {
          padding: 30px 30px;
          width: 100%;
          border-radius: 0;
        }
        h2 {
          color: #008080;
          font-size: 36px;
          font-weight: 500;
          margin-bottom: 30px;
          @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 20px;
          }
        }
        h4 {
          color: #ffffff;
          font-size: 30px;
          font-weight: 500;
          margin-bottom: 20px;
          @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 12px;
          }
        }
        p {
          font-size: 16px;
          color: #1a1a1a;
          font-weight: 400;
          padding-right: 15px;
          margin-bottom: 41px;
          text-align: left;
          @media #{$sm-layout} {
            margin-bottom: 24px;
            padding-right: 0;
          }
        }
      }
    }
    .thumbnail {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      @media #{$lg-layout} {
        position: static;
        height: 100%;
      }
      @media #{$md-layout} {
        position: static;
        height: 100%;
      }
      @media #{$sm-layout} {
        position: static;
        height: 100%;
      }
      img {
        border-radius: 10px;
        @media #{$lg-layout} {
          border-radius: 0;
        }
        @media #{$md-layout} {
          border-radius: 0;
        }
        @media #{$sm-layout} {
          border-radius: 0;
        }
      }
    }
  }
}
</style>
<style>
.slick-next::before,
.slick-prev::before {
  color: #008080 !important;
}
</style>
