<template>
  <div>
    <!-- Start Contact Address Area  -->
    <div class="rn-contact-top-area ptb--80 bg_color--5">
      <v-container>
        <div class="text-center section-title mb--30">
          <h2 class="app-header-1">Contact us</h2>
          <p class="description">
            If you would like to discuss anything please drop us a message and
            one of our team members will contact you.
          </p>
        </div>
        <ContactAddress
          data-aos="slide-right"
          data-aos-easing="ease"
          data-aos-duration="700"
          data-aos-once="true"
        />
      </v-container>
    </div>
    <div class="rn-contact-page ptb--70 bg_color--1">
      <v-container>
        <Contact>
          <v-img
            class="rounded-lg"
            slot="contact-img"
            src="../assets/images/stockpics/healthcare_small/healthcare_007.jpg"
            alt="RHS24 Logo"
            aspect-ratio="0.99"
          />
        </Contact>
      </v-container>
    </div>
    <!-- Start Google MAp -->
    <div class="google-map rn-contact-map-area position-relative ptb--70">
      <div class="mapouter">
        <GoogleMap class="gmap_canvas" />
      </div>
    </div>
    <!-- End Google MAp -->
  </div>
</template>
<script>
import ContactAddress from "../components/ContactAddress";
import Contact from "../components/Contact";
import GoogleMap from "../components/GoogleMap";
export default {
  components: { ContactAddress, Contact, GoogleMap },
};
</script>
<style lang="scss">
.mapouter {
  position: relative;
  width: 100%;
  height: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 600px;
  width: 100%;
  @media only screen and (max-width: 1199px) {
    height: 500px;
  }
  @media only screen and (max-width: 992px) {
    height: 420px;
  }
}
.app-header-1 {
  color: #008080;
  font-weight: 500;
}
</style>
