import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Home from "../views/Home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    meta: {
      title: "RHS24",
    },
  },  
  {
    path: "/about-us",
    name: "about-us",
    meta: {
      title: "About Us - RHS24",
    },
    component: () => import("../views/AboutUs"),
  },  
  {
    path: "/domiciliary-care",
    name: "domiciliary-care",
    meta: {
      title: "Domiciliary Care - RHS24",
    },
    component: () => import("../views/DomiciliaryCare"),
  },   
  {
    path: "/supported-living",
    name: "supported-living",
    meta: {
      title: "Supported Living - RHS24",
    },
    component: () => import("../views/SupportedLiving"),
  },   
  {
    path: "/staffing-services",
    name: "staffing-services",
    meta: {
      title: "Staffing Services - RHS24",
    },
    component: () => import("../views/Service"),
  },  
  {
    path: "/contact-us",
    name: "contact-us",
    meta: {
      title: "Contact us: RHS24",
    },
    component: () => import("../views/Contact"),
  },
  {
    path: "/apply-online",
    name: "apply-online",
    meta: {
      title: "Jobs at: RHS24",
    },
    component: () => import("../views/ApplicationForm"),
  },
  {
    path: "/downloads",
    name: "downloads",
    meta: {
      title: "Downloads: RHS24",
    },
    component: () => import("../views/Downloads"),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = { target: ".app-main", offset: 200 };
    if (to.hash) {
      scrollTo.target = to.hash;
    } else if (savedPosition) {
      scrollTo.target = savedPosition.y;
    }
    if (to.path == "/") {
      return goTo(0);
    } else {
      return goTo(scrollTo.target, { offset: scrollTo.offset });
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
