<template>
  <ValidationObserver ref="formRef" tag="form">
    <v-container>
      <v-row>
        <v-col cols="12">
          <ValidationProvider
            :immediate="true"
            :rules="{ max: 100, required: true }"
            v-slot="{ errors }"
          >
            <v-text-field
              color="blue lighten-1"
              label="Your name *"
              v-model.trim="fdata.enq_PersonName"
              maxlength="100"
              :error-messages="errors"
              clearable
              outlined
            >
            </v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12">
          <ValidationProvider
            :immediate="true"
            :rules="{ max: 100, required: true }"
            v-slot="{ errors }"
          >
            <v-text-field
              color="blue lighten-1"
              label="Mobile / Telephone number *"
              v-model.trim="fdata.enq_Mobile"
              maxlength="100"
              :error-messages="errors"
              clearable
              outlined
            >
            </v-text-field>
          </ValidationProvider>
        </v-col>

        <v-col cols="12">
          <ValidationProvider
            :immediate="true"
            :rules="{ max: 100, email: true }"
            v-slot="{ errors }"
          >
            <v-text-field
              color="blue lighten-1"
              label="E-mail"
              v-model.trim="fdata.enq_Email"
              maxlength="100"
              :error-messages="errors"
              clearable
              outlined
            >
            </v-text-field>
          </ValidationProvider>
        </v-col>

        <v-col cols="12">
          <ValidationProvider
            :immediate="true"
            :rules="{ max: 1000, required: true }"
            v-slot="{ errors }"
          >
            <v-textarea
              color="blue lighten-1"
              label="Your enquiry *"
              v-model.trim="fdata.enq_Message"
              maxlength="1000"
              :error-messages="errors"
              clearable
              outlined
            >
            </v-textarea>
          </ValidationProvider>
        </v-col>
        <v-col class="py-1 text-center" cols="12" justify-content>
          <v-btn
            @click="saveFormData"
            :loading="showBusyAni"
            color="primary"
            x-large
            dark
            width="150"
            >Submit</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="showSnackbar = false" text> Close </v-btn>
      </template>
    </v-snackbar>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import axios from "axios";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    fdata: {
      enq_PersonName: "",
      enq_Mobile: "",
      enq_Email: "",
      enq_Message: "",
    },
    showSnackbar: false,
    snackbarMessage: "",
    snackbarColor: "error",
    showBusyAni: false,
  }),
  methods: {
    saveFormData: async function () {
      //debugger;
      this.snackbarColor = "error";
      let isValid = await this.$refs.formRef.validate();
      if (!isValid) {
        this.snackbarMessage = "Form contains validation warning(s).";
        this.showSnackbar = true;
        return;
      }
      this.showBusyAni = true;
      const actionUrl = "/Contact/SubmitEnquiry";
      try {
        const status = await axios.post(actionUrl, this.fdata);
        if (status) {
          this.showBusyAni = false;
          Object.assign(this.fdata, {
            enq_PersonName: "",
            enq_Mobile: "",
            enq_Email: "",
            enq_Message: "",
          });
          this.snackbarColor = "success";
          this.snackbarMessage = "Form successfully submitted.";
          this.showSnackbar = true;
        }
      } catch (error) {
        this.showBusyAni = false;
        throw error;
        S;
      }
    },
  },
};
</script>
