import { render, staticRenderFns } from "./Accreditation.vue?vue&type=template&id=5badc7bb&scoped=true"
import script from "./Accreditation.vue?vue&type=script&lang=js"
export * from "./Accreditation.vue?vue&type=script&lang=js"
import style0 from "./Accreditation.vue?vue&type=style&index=0&id=5badc7bb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5badc7bb",
  null
  
)

export default component.exports