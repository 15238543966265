<template>
  <div>
    <VueSlickCarousel
      v-bind="settings"
      class="slider-digital-agency slider-activation rn-slick-dot dot-light mb--0 color-white"
    >
      <div
        class="slide slide-style-2 fullscreen d-flex align-center bg_image"
        data-black-overlay="5"
        v-for="(slider, i) in sliderContent"
        :key="i"
        :style="{ backgroundImage: 'url(' + slider.src + ')' }"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-center inner">
                <h1 class="heading-title">{{ slider.title }}</h1>
                <p class="description">
                  {{ slider.desc }}
                </p>
                <div class="slide-btn">
                  <router-link class="btn-default" to="/contact-us"
                    >Contact Us</router-link
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      sliderContent: [
        {
          src: require("../assets/images/stockpics/healthcare/healthcare_001.jpg"),
          title: "We Recruit Nurses, Care Assistants & Support Workers",
          desc: `We provide qualified staff to an extensive range of clients`,
        },
        {
          src: require("../assets/images/stockpics/healthcare/healthcare_005.jpg"),
          title:
            "We are specialists in covering temporary and permanent staffing needs",
          desc: `We offer a wide range of roles to suit those who require flexible shifts or temporary employment within hospitals, social care and other healthcare environments.`,
        },
        // {
        //   src: require("../assets/images/stockpics/healthcare/healthcare_012.jpg"),
        //   title: "Quality Supported Living Services",
        //   desc: `Helping individuals live better through our services is our priority. For us, their needs matter the most. We make sure our service users are comfortable and get a better perception of life.`,
        // },
        {
          src: require("../assets/images/stockpics/healthcare/healthcare_007.jpg"),
          title: "Excellent rates of pay",
          desc: `Our staff are our priority, which is why at RHS24 we offer competitive pay rates to ensure they stay happy and able to deliver the best possible service to every client.`,
        },
        {
          src: require("../assets/images/stockpics/healthcare/healthcare_002.jpg"),
          title:
            "Looking for temporary or permanent staff? You may request now.",
          desc: `Our staff are our priority, which is why at RHS24 we offer competitive pay rates to ensure they stay happy and able to deliver the best possible service to every client.`,
        },
        {
          src: require("../assets/images/stockpics/healthcare/healthcare_003.jpg"),
          title: "Call us now on :",
          desc: `(0116) 319-5972 or (0796) 001-9170`,
        },
      ],
      settings: {
        autoplay: true,
        autoplaySpeed: 6000,
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        margin: 20,
        adaptiveHeight: true,
      },
    };
  },
};
</script>
<style>
.slick-slide img {
  display: block;
  width: 100%;
}
</style>
