<template>
  <div>
    <ServiceDashboard />
    <OurValues/>
    <CqcOverview/>
    <Accreditation/>
    <Contact />
  </div>
</template>
<script>
import ServiceDashboard from "../components/ServiceDashboard";
import OurValues from "../components/OurValues";
import CqcOverview from "../views/CqcOverview";
import Accreditation from "../views/Accreditation";
import Contact from "../views/Contact";

export default {
  components: {
    ServiceDashboard,
    OurValues,
    CqcOverview,
    Accreditation,
    Contact,
  },
};
</script>
