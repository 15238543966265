<template>
  <div class="rn-brand-area ptb--120">
    <v-container>
      <h2 class="text-center app-header-1">Accreditations</h2>
      <Accreditation />
    </v-container>
  </div>
</template>
<script>
import Accreditation from "../components/Accreditation";
export default {
  components: {
    Accreditation,
  },
};
</script>
<style scoped>
.app-header-1 {
  color: #008080;
  font-weight: 500;
}
</style>
