import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./helpers/VeeValidateRules";
import vuetify from "./plugins/vuetify";
// import VueParticles from "vue-particles";
// import CoolLightBox from "vue-cool-lightbox";
import VueScrollactive from "vue-scrollactive";
import { VueReCaptcha } from "vue-recaptcha-v3";
import AOS from "aos";
import "aos/dist/aos.css";
import * as VueGoogleMaps from "vue2-google-maps";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import "./assets/scss/main.scss";

Vue.config.productionTip = false;

// Vue.use(VueParticles);
// Vue.use(CoolLightBox);
Vue.use(VueReCaptcha, {
  siteKey: "6Lfb9rUhAAAAAFYab8gGneabEM_veeJEki1Mok1M",
  // loaderOptions: {
  //   render: { container: "footer", badge: "bottomleft", size: "invisible" },
  // },
});
Vue.use(VueScrollactive);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB5GHcEmAiTW4c4t0Xm16Qc_HVjUZdIDPQ",
    libraries: "places", // necessary for places input
    region: "uk,en",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted() {
    AOS.init();
  },
}).$mount("#app");
